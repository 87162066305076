<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>轮播图管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button @click="add('new', '')" size="small">添加</el-button>
    </div>
    <el-row>
      <el-col :span="24">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <!-- <el-tab-pane v-for="(item,index) in filterType" :key="index" :label="item.name" :name="item.name">
            </el-tab-pane> -->
          <el-tab-pane label="首页" name="one"></el-tab-pane>
          <el-tab-pane label="社区" name="two"></el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column label="图片" prop="url">
        <template slot-scope="scope">
          <!-- <el-popover placement="top-start" title="" trigger="hover"> -->
          <!-- <img :src="scope.row.url" alt=""> -->
          <img slot="reference" :src="scope.row.url" style="width: 130px" />
          <!-- </el-popover> -->
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="opt doc">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="add('bj', scope.row)"
              >编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="del(scope.row, scope.$index)"
              >删除
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="form" label-width="170px">
        <el-form-item label="轮播图">
          <upload2 :src.sync="form.pic" :width="750" :height="180"></upload2>
        </el-form-item>
        <el-form-item label="轮播图位置">
          <el-select
            style="float: left"
            v-model="form.filter_type"
            placeholder="请选择轮播图位置"
          >
            <el-option
              v-for="item in filterType"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <p class="articleTips">如需跳转到指定文章，请在下方进行选择。</p>
        <el-form-item label="选择文章">
          <!-- <el-form-item label=""> -->
          <el-input
            style="width: 230px;float:left;"
            v-model="extend_name"
            placeholder="请选择文章"
            @focus="choiceArticle"
          ></el-input>
          <!-- </el-form-item>clearable
            @clear="cleararticle" -->
          <!-- <el-button>选择文章</el-button> -->
          <!-- <el-select
            filterable
            style="float: left"
            v-model="form.extend_id"
            placeholder="请选择文章"
          >
            <el-option
              v-for="item in extend"
              :key="item.value"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogFormVisible = false;
            form = {};
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="选择文章" :visible.sync="choicearticle" width="60%">
      <el-table
        :data="articlelist"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
        tooltip-effect="dark"
        height="300px"
      >
        <el-table-column width="55">
          <template scope="scope">
            <el-radio
              @change="getchoicerow(scope.row)"
              :label="scope.row.id"
              v-model="choiceArticleId"
              ><span></span></el-radio
            >
          </template>
        </el-table-column>
        <el-table-column label="编号" prop="id" width="80"></el-table-column>
        <el-table-column prop="id" v-if="false"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
      </el-table>
      <p class="paging">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-sizes="pageSizes"
          :page-size="PageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount"
          :hide-on-single-page="value"
        ></el-pagination>
      </p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="choicearticle = false">取 消</el-button>
        <el-button type="primary" @click="surearticle">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import upload2 from "../components/upload2";
export default {
  components: {
    upload2,
  },
  inject: ["reload"],
  data() {
    return {
      activeName: "one",
      dialogFormVisible: false,
      form: {
        pic: "",
        filter_type: "",
        extend_id: "",
      },
      list: [],
      title: "添加轮播图",
      type: "",
      item: "",
      filterType: [
        { id: 1, name: "首页" },
        { id: 2, name: "社区" },
      ],
      extend: [],
      articlelist: [],
      choicearticle: false,
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      multipleSelection: [],
      extend_name: "",
      choiceArticleId: "",
      choiceArticleMsg:'',
    };
  },
  mounted() {
    this.getbanner(1);
    // this.getarticle(1, 1000);
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getarticle(currentPage);
    },
    getarticle(page) {
      this.axios
        .get("/gu/get_gu_articles?page=" + page + "&size=" + this.PageSize)
        .then((rs) => {
          if (rs.data.code == 0) {
            this.articlelist = rs.data.result;
            this.totalCount = rs.data.count;
            // this.extend.unshift({ id: "-1", title: "无" });
          } else {
            console.log(rs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    choiceArticle() {
      this.choicearticle = true;
      // this.multipleSelection = [];
      this.choiceArticleId = '';
      this.choiceArticleMsg = '';
      this.getarticle(1);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getchoicerow(row) {
      console.log(row);
      this.choiceArticleMsg = row;
    },
    cleararticle() {
      this.$confirm("此操作将解绑该文章, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.form.extend_id = "";
          this.extend_name = "";
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消解绑",
          });
          // this.form.extend_id = this.item.extend_id;
          // // this.extend_name = "";
          // this.axios
          //   .get("/gu/get_gu_article_by_id?id=" + this.item.extend_id)
          //   .then((res) => {
          //     if (res.data.code == 0) {
          //       this.extend_name = res.data.result.title;
          //     } else {
          //       console.log(res);
          //     }
          //   });
        });
    },
    surearticle() {
      // if (this.multipleSelection.length > 1) {
      //   this.$message({
      //     message: "只能选择一篇文章",
      //     type: "error",
      //   });
      // } else if (this.multipleSelection.length == 1) {
        this.form.extend_id = this.choiceArticleMsg.id;
        this.extend_name = this.choiceArticleMsg.title;
        this.choicearticle = false;
      // } else {
      //   this.choicearticle = false;
      // }
    },
    getbanner(filter_type) {
      this.axios
        .get("/gu/get_gu_banners?filter_type=" + filter_type)
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    add(type, row) {
      this.dialogFormVisible = true;
      this.type = type;
      this.item = row;
      if (type != "new") {
        this.title = "编辑轮播图";
        this.form.pic = row.url;
        if (row.extend_id == "") {
          this.form.extend_id = "";
          this.this.extend_name = "";
        } else {
          this.form.extend_id = Number(row.extend_id);

          this.axios
            .get("/gu/get_gu_article_by_id?id=" + row.extend_id)
            .then((res) => {
              if (res.data.code == 0) {
                this.extend_name = res.data.result.title;
              } else {
                console.log(res);
              }
            });
        }
        this.form.filter_type = row.filter_type;
      } else {
        this.form.pic = "";
        this.form.filter_type = "";
        this.form.extend_id = "";
        this.extend_name = "";
        this.title = "添加轮播图";
      }
    },
    sub() {
      let form = {};
      form.url = this.form.pic;
      form.type = "";
      if (this.form.extend_id == -1) {
        form.extend_id = "";
      } else {
        form.extend_id = this.form.extend_id;
      }
      form.filter_type = this.form.filter_type;
      let url = "";
      let str = "";
      if (this.type == "new") {
        url = "/gu/insert_gu_banner";
        str = "添加成功";
      } else {
        url = "/gu/update_gu_banner";
        form.id = this.item.id;
        str = "修改成功";
      }
      if (form.url == "") {
        this.$message({
          message: "请上传轮播图",
          type: "error",
        });
        return;
      }
      if (form.filter_type == "") {
        this.$message({
          message: "请选择位置",
          type: "error",
        });
        return;
      }
      // console.log(form);
      this.axios
        .post(url, this.qs.stringify(form))
        .then((res) => {
          if (res.data.code == 0) {
            this.dialogFormVisible = false;
            this.form = {};
            this.$message({
              message: str,
              type: "success",
            });
            this.reload();
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    del(row, index) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/gu/delete_gu_banner", this.qs.stringify({ id: row.id }))
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleClick(value) {
      let num = Number(value.index) + 1;
      this.list = [];
      this.getbanner(num);
    },
  },
};
</script>
<style>
.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.articleTips {
  text-align: left;
  padding-left: 100px;
  color: #909399;
}
</style>
